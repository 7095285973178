export async function getName() {
  // identity comes from the cookies
  const req = await fetch('https://auth.votey.madetobuild.dev/user', {
    credentials: 'include',
    mode: "cors",
    headers: {
      Accept: 'application/json',
    }
  });
  const text = await req.json();

  console.log("HiUser req: %o", req);
  if (req.ok) {
    return text.nickname;
  }
  if (req.status === 404) {
    return "";
  }
  throw new Error(text && text.error ? text.error : JSON.stringify(text));
}