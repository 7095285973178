<script lang="ts">
  import svelteLogo from './assets/svelte.svg'
  import Counter from './lib/Counter.svelte'
  import HiUser from './lib/HiUser.svelte';
    import LoginLink from './lib/LoginLink.svelte';
</script>


<header>
  <div class="corner">
    <HiUser />
    <LoginLink />
  </div>
  <h1 style="margin-top: 0;padding-top: 20px;">Minitools</h1>
</header>
<div class="container">
  <form method="get" action="/availability.html">
    <button class="giant">Availability</button>
  </form>
</div>