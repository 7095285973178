<script lang="ts">
  import { getName } from "./Api";

  let name = getName();
</script>

{#await name}
  Hello!
{:then name}
  Hello, {name}!
{/await}
