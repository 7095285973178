<script lang="ts">
  import { getName } from "./Api";

  let name = getName();
</script>

<!-- svelte-ignore empty-block -->
{#await name}
{:then name}
  {#if !name}
    <a href="/auth.html" rel="prefetch">Log in</a>
  {:else}
  <a href="/log_out.html">Log out</a>
  {/if}
{:catch}
  <a href="/auth.html">Log in</a>
{/await}
